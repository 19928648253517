import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import classes from './SideDrawer.module.css';
import Logo from '../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/action/index';

const SideDrawer = () => {
    const dispatch = useDispatch();
    const [isClosing, setIsClosing] = useState(false);

    const sideDrawerState = useSelector(state => state.layout.sideDrawerState);
    const moveToHome = useSelector(state => state.layout.moveToHomeState);

    // Function to navigate to the home page and close the drawer
    const navigateToHomePage = () => {
        dispatch(actions.showSideDrawer(false));
        dispatch(actions.showDrawerToggle(true));
        dispatch(actions.moveToHomePage(true));
    }

    // Function to close the drawer with fade-out animation
    const closeSideDrawerMethod = () => {
        setIsClosing(true);
        setTimeout(() => {
            dispatch(actions.showSideDrawer(false)); // Actually close the drawer after fade-out
            dispatch(actions.showDrawerToggle(true));
            setIsClosing(false); // Reset closing state after animation
        }, 500); // Matches fade-out duration (1s)
    }

    // Redirect to home if moveToHome is true
    let home = null;
    if (moveToHome) {
        home = <Redirect to="/" />;
    }

    // Reset the moveToHome state in redux when it's triggered
    useEffect(() => {
        dispatch(actions.moveToHomePage(false));
    }, [moveToHome, dispatch]);

    // Conditionally render the SideDrawer with fadeIn or fadeOut class
    let sideDrawer = null;

    if (sideDrawerState || isClosing) {
        sideDrawer = (
            <div className={`${classes.SideDrawer} ${sideDrawerState ? classes.FadeIn : classes.FadeOut}`}>
                {home}
                <div>
                    <Logo clicked={navigateToHomePage} />
                </div>
                <div>
                    <NavigationItems clicked={closeSideDrawerMethod} />
                </div>
            </div>
        );
    }

    return <div>{sideDrawer}</div>;
}

export default SideDrawer;
