import React, { useState,useRef } from 'react';
import classes from './ContactUs.module.css';
import Popup from '../Popup/Popup';
import Spinner from '../Spinner/Spinner';
import Backdrop from '../Backdrop/Backdrop';
import emailjs from 'emailjs-com';
const ContactUs = (props) => {

    const [spinnerState,setSpinnerState] = useState({loading: false,message: ""});
    

    const [formData,setFormData] = useState({name: "",reply_to: "",subject: "",message: ""});

    const form = useRef();

    function validateEmail(reply_to) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(reply_to).toLowerCase());
    }


    const sendEmail = (event) => {
        event.preventDefault();

        if(!validateEmail(formData.reply_to)){
            setSpinnerState({loading: false,message: "Enter correct email"});
            return;
        }

        setSpinnerState({loading: true,message: ''});

        emailjs.sendForm('service_u4w1vq6', 'template_5qfnwbs', event.target, 'WdISo4K5U08iH7-Rh')
        .then((result) => {
            setFormData({name: "",reply_to: "",subject: "",message: ""});
            setSpinnerState({loading: false,message: 'Email successfully sent'});
        }, (error) => {
            setSpinnerState({loading: false,message: "Did not send, please try again"});
        });

        event.target.reset();
    }

    const inputChangedHandler = (event,identifier) => {

        let updateedFormValues = {
                ...formData,
                [identifier]: event.target.value.trim()
        }
        setFormData(updateedFormValues);
    }

    const removePopUp = (response) => {
        if(response === 'OK'){
            setSpinnerState({loading: false,message: ""});
        }
    }
    let theSpinner = null;
    let backDrop = null;
    let popup = null;
    if(spinnerState.loading){
        theSpinner = <Spinner />
        backDrop = <Backdrop sideDrawerState={spinnerState.loading} />
    }
    if((spinnerState.message).length !== 0){
        popup = <Popup message={spinnerState.message} type="oneButton" clicked={removePopUp}/>
        backDrop = <Backdrop sideDrawerState={true} />
    }

    return <div id={props.id}>
               <div className={classes.ContactUs}>
                    <div className={classes.ContactUs__header}>
                        <h1>Contact Us</h1>
                        <div className={classes.ContactUs__underline}></div>
                    </div>
                    {popup}
                    <div className={classes.ContactUs_Spinner}>
                            {backDrop}
                            {theSpinner}
                    </div>
                    
                    <div className={classes.ContactUsContent}>
                            <div className={classes.ContactLeft}>
                                    <div>
                                        <h1>Build By Compilation Tech</h1>
                                    </div>
                                    <div>
                                        <p>We believe that working <strong>Together</strong> we can accomplish <strong>Everything</strong>.</p>
                                    </div>
                                    <div className={classes.ContactInfo}>
                                        <strong>Phone: </strong><span>(+27) 72 581 5206</span><br/>
                                        <strong>Email: </strong><span>thecompilationtech@gmail.com</span>
                                    </div>
                    
                            </div>
                            <div className={classes.ContactRight}>

                                <h1>Get In Touch With Us</h1>
                                <form ref={form}  onSubmit={sendEmail} className={classes.ContactForm}>
                                    <input type="text" name="name" placeholder="Name" value={formData.name} onChange={event => inputChangedHandler(event,"name")} required maxLength="30"/>
                                    <input type="email" name="reply_to" placeholder="Email" value={formData.email} onChange={event => inputChangedHandler(event,"reply_to")} required maxLength="40"/>
                                    <input type="text" name="subject" placeholder="Email subject" value={formData.subject} onChange={event => inputChangedHandler(event,"subject")} required maxLength="40"/>
                                    <textarea cols="10" rows="5" name="message" placeholder="Message" value={formData.description} onChange={event => inputChangedHandler(event,"message")} required maxLength="100"></textarea>
                                    <button>SUBMIT</button>
                                </form>

                            </div>

                        </div>
            </div>
            <footer className={classes.ContactFooter}>
                    <p>Copyrights © {new Date().getFullYear()} All Rights Reserved By Compilation Tech</p>
            </footer>
        </div>
}

export default ContactUs;