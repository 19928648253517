import React, {useState, useEffect} from 'react';
import {FaArrowAltCircleRight, FaArrowAltCircleLeft}  from 'react-icons/fa';
import classes from './ImageSlider.module.css';

const ImageSlider = (props) => {
    
    const [current, setCurrent] = useState(0);
    const slides = props.slides;
    const length = slides[0].imageUrl.length;
    
    const prevSlider = () => {
        setCurrent(current === length -1 ? 0 : current + 1);
    }
    const nextSlider = () => {
        const thecurrent = current === 0 ? length -1 : current - 1;
        setCurrent(thecurrent);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            prevSlider();
        }, 5000);
        return () => clearTimeout(timer);
   });
     
    if(!Array.isArray(slides) || slides.length <=0){
        return null; 
    }

    const sliderData = slides[0].imageUrl.map((slider,index) => {
        if(index === current){
            return <img src={slides[0].imageUrl[current]} alt="travel" key={index}/>;
        }
        return null;
    });

    let description =  <div className={classes.ImageSliderInfoAdress}>
                            <h5><span className={classes.ImageSliderInfoColor}>Description:</span>&nbsp; {slides[0].decription}</h5>
                        </div>
    
    return <div className={classes.ImageSlider}>
                    <div className={classes.ImageSliderCancelButton}>
                        <button onClick={props.clicked}>X</button>
                    </div>
                    <div className={classes.ImageSliderDescription}>
                        <div className={classes.ImageSliderInfoAdress}>
                            <h5><span className={classes.ImageSliderInfoColor}>Address:</span>&nbsp; {slides[0].address}</h5>
                        </div>
                        {slides[0].decription.length != 0 ? description : null}
                        <div className={classes.ImageSliderInfo}>
                            <div className={classes.ImageSliderInfoFirstPart}>
                                <h5><span className={classes.ImageSliderInfoColor}>Province:</span> {slides[0].province}</h5>
                                <h5><span className={classes.ImageSliderInfoColor}>Location:</span> {slides[0].location}</h5>
                                <h5><span className={classes.ImageSliderInfoColor}>Contact:</span> {slides[0].contact}</h5>
                            </div>
                            <div className={classes.ImageSliderInfoSecondPart}>
                                <h5><span className={classes.ImageSliderInfoColor}>Section:</span>&nbsp;&nbsp; {slides[0].section}</h5>
                                <h5><span className={classes.ImageSliderInfoColor}>Price:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; R{slides[0].price}</h5>
                            </div>
                        </div>
                    </div>
                    <div className={classes.ImageSliderMiddle}>
                        <div className={classes.ImageSliderContainer}>
                            <FaArrowAltCircleLeft className={classes.leftArrow} onClick={prevSlider} />
                            {sliderData}
                            <FaArrowAltCircleRight className={classes.rightArrow} onClick={nextSlider} />
                        </div>
                    </div>
          </div>;
        
    
}
  
export default ImageSlider;