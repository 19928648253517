import classes from './AvailablePropertyImageCard.module.css';
import saltPas from '../../SaltPas/SaltPas';
import md5 from 'md5';
import { useSelector } from 'react-redux';

const AvailablePropertyImageCard = (props) => {

    const username = useSelector(state => {
        return state.auth.username;
    });
    const admin = useSelector(state => {
        return state.auth.admin;
    });

    return props.the_images_arry.map((imageArray,index) => {
        let the_address = imageArray[0].address;
        let whiten_address1 = '';
        let whiten_address2 = '';
        let whiten_address3 = '';
        let whiten_address4 = '';
        if(the_address.length > 19){
            the_address = imageArray[0].address.substring(0,15);
            whiten_address1 = imageArray[0].address.substring(15,32);
            whiten_address2 = imageArray[0].address.substring(32,33);
            whiten_address3 = imageArray[0].address.substring(33,34);
            whiten_address4 = imageArray[0].address.substring(34,35);
        }

        let roomTheState = null;
        let deleteRoom = null;
        let conditionForAdmin  = false;

        for (let i = 0; i < admin.length; i++) {
            const userN = md5(admin[i])+saltPas;
            conditionForAdmin = username=== userN.substring(15, userN.length)?true:false;
            if(conditionForAdmin){
                break;
            }
        }
        
        if(conditionForAdmin){

            deleteRoom = <div>
                                {props.deleteProperty === "display" ? <button onClick={() => props.clickedDelete(imageArray[0].ids)}>Delete Room</button>: null}
                            </div>

            if(props.type == "availableRoom"){
                roomTheState = <div>
                                 {imageArray[0].roomStatus === "Unknown"?<p disabled  className={classes.paragraphState}>Status: Unknown</p>:imageArray[0].roomStatus === "Taken"?<p  disabled  className={classes.paragraphState}>Status: Taken</p>:imageArray[0].roomStatus === "Available"?<p  disabled  className={classes.paragraphState}>Status: Available</p>:null}
                               </div>
            }else if(props.type == "updateRoom"){
                roomTheState = <div>
                                    {props.deleteProperty === "display" ? <button onClick={() => props.updateRoom(imageArray[0].ids,imageArray[0].roomStatus === "Unknown" || imageArray[0].roomStatus === "Taken"?"Available":"Taken")}>{imageArray[0].roomStatus === "Unknown" || imageArray[0].roomStatus === "Taken" ?"Update From "+imageArray[0].roomStatus+" To Available":"Update From Available To Taken"}</button>: ""}
                                </div>
            }

        }else if(props.type == "availableRoom"){
            roomTheState = <div>
                             {imageArray[0].roomStatus === "Unknown"?<p disabled  className={classes.paragraphState}>Status: Unknown</p>:imageArray[0].roomStatus === "Taken"?<p  disabled  className={classes.paragraphState}>Status: Taken</p>:imageArray[0].roomStatus === "Available"?<p  disabled  className={classes.paragraphState}>Status: Available</p>:null}
                           </div>
        }else if(props.type == "updateRoom"){
            roomTheState = <div>
                                {props.deleteProperty === "display" ? <button onClick={() => props.updateRoom(imageArray[0].ids,imageArray[0].roomStatus === "Unknown" || imageArray[0].roomStatus === "Taken"?"Available":"Taken")}>{imageArray[0].roomStatus === "Unknown" || imageArray[0].roomStatus === "Taken" ?"Update From "+imageArray[0].roomStatus+" To Available":"Update From Available To Taken"}</button>: ""}
                            </div>
        }

        return <div className={classes.AvailablePropertyImageCard} key={index}>
                    <div className={classes.AvailablePropertyImageCardFunctions}>
                        <div>
                             <button style={{ boxShadow: 'none' }} onClick={() => props.clicked(imageArray[0].id)}>View Full Info</button>
                        </div>
                        {conditionForAdmin?deleteRoom:null}
                        {roomTheState}
                    </div>
                    <div className={classes.AvailablePropertyImageCardInfo}>
                        <div  className={classes.AvailablePropertyImageCardAddress}>
                            <h5>Address:&nbsp;&nbsp;  {the_address}<span className={classes.whitenAddress1}>{whiten_address1}</span>
                            <span className={classes.whitenAddress2}>{whiten_address2}</span>
                            <span className={classes.whitenAddress3}>{whiten_address3}</span>
                            <span className={classes.whitenAddress4}>{whiten_address4}</span>
                            </h5>
                        </div>
                        <div className={classes.AvailablePropertyImageCardDetails}>
                            <div className={classes.AvailablePropertyImageCardFirstPart} >
                                <h5>Province:&nbsp;&nbsp;{imageArray[0].province}</h5>
                                <h5>Location:&nbsp;&nbsp; {imageArray[0].location}</h5>
                                <h5>Section:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{imageArray[0].section}</h5>
                            </div>
                            <div  className={classes.AvailablePropertyImageCardSecondPart} >
                                <h5>Price:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;R{imageArray[0].price}</h5>
                                <h5>Contact:&nbsp;&nbsp; {imageArray[0].contact}</h5>
                            </div>
                        </div>
                        

                    </div>
                    <div className={classes.AvailablePropertyImageCardImage} onClick={() => props.clicked(imageArray[0].id)}>
                         <img src={imageArray[0].imageUrl[0]} alt=""/>
                    </div>
                    
                </div>;
    });
}
export default AvailablePropertyImageCard;