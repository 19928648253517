import React, { useState, useEffect } from 'react';
import classes from './Backdrop.module.css';

const Backdrop = (props) => {
    const [isFadingOut, setIsFadingOut] = useState(false);

    // Handle backdrop click and trigger fade-out
    const handleClick = () => {
        setIsFadingOut(true);  // Start fade-out animation
        setTimeout(() => {
            props.clicked();    // Call the clicked function after the fade-out completes
            setIsFadingOut(false); // Reset fading state for next usage
        }, 500);  // Matches the fade-out duration in CSS (1s)
    };

    return (
        <div
            className={`${classes.Backdrop} ${isFadingOut ? classes.FadeOut : classes.FadeIn}`}
            onClick={handleClick}
        ></div>
    );
};

export default Backdrop;
