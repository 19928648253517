import React, { useRef } from "react";
import {useEffect,useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import * as actions from '../../store/action/index';
import classes from './DeleteProperty.module.css';
import AvailablePropertyImageCard from '../../components/AvailablePropertyImageCard/AvailablePropertyImageCard';
import DescriptionSection from '../../components/DescriptionSection/DescriptionSection';
import ContactUs from '../../components/ContactUs/ContactUs';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import { Redirect } from 'react-router-dom';
import firebase from '../../firebaseStorage/firebase';
import Spinner from '../../components/Spinner/Spinner';
import Backdrop from '../../components/Backdrop/Backdrop';
import Popup from '../../components/Popup/Popup';
import md5 from 'md5';
import saltPas from '../../SaltPas/SaltPas';
const DeleteProperty = () => {

    const dispatch = useDispatch();
    dispatch(actions.propertysearching('','','',false));

    const username = useSelector(state => {
        return state.auth.username;
    });
    const authenticated = useSelector(state => {
        return state.auth.authenticated;
    });

    const admin = useSelector(state => {
        return state.auth.admin;
    });

    const [arrayOfArraysOfImageObjects,setArrayOfArraysOfImageObjects] = useState([]);
    const [spinnerState,setSpinnerState] = useState({loading: false,message: ""});
    const [confirmDelete,setConfirmDelete] = useState({'state':false,'arr':[]});
    const [deletedImageId,setDeletedImageId] = useState('');

    const [refLoading,setRefLoading] = useState(true);

    const firestoreRef = firebase.firestore();
    const storageRef = firebase.storage().ref('roomFinder');

    let conditionForAdmin  = false;

    for (let i = 0; i < admin.length; i++) {
        const userN = md5(admin[i])+saltPas;
        conditionForAdmin = username=== userN.substring(15, userN.length)?true:false;
        if(conditionForAdmin){
            break;
        }
    }

    useEffect(() =>{
        let tempArraysOfObject = [];
        setRefLoading(true);
        firestoreRef.collection('roomFinder').onSnapshot(snapshort => {
            let changes = snapshort.docChanges();
            
            changes.forEach(change => {
                const id = change.doc.id;
                if(id.includes(username)){
                    const data = change.doc.data();

                    //--------------------
                    let date = new Date(data.date);

                    let year = date.getFullYear();
                    let month = String(date.getMonth() + 1).padStart(2, '0'); 
                    let day = String(date.getDate()).padStart(2, '0');
                    
                    let formattedDate = `${year}-${month}-${day}`;
                    
                    //---------------------
                
                    if(change.type === "added"){
                        tempArraysOfObject.push([{'roomStatus':data.roomStatus,'date':formattedDate,'province': data.province,'section': data.section,'location': data.location,'address': data.address,'contact': data.contact,'decription': data.decription,'price': data.price,'id': change.doc.id,'imageUrl': data.imageUrl,'ids':data.ids}]);
                    }else if(change.type === "removed"){
                        //arraysOfObject.push([{'province': data.province,'section': data.section,'location': data.location,'address': data.address,'contact': data.contact,'decription': data.decription,'price': data.price,'id': change.doc.id,'imageUrl': data.imageUrl}]);
                    }

                }else if(conditionForAdmin){
                    const data = change.doc.data();

                    //--------------------
                    let date = new Date(data.date);

                    let year = date.getFullYear();
                    let month = String(date.getMonth() + 1).padStart(2, '0'); 
                    let day = String(date.getDate()).padStart(2, '0');
                    
                    let formattedDate = `${year}-${month}-${day}`;
                    
                    //---------------------
                
                    if(change.type === "added"){
                        tempArraysOfObject.push([{'roomStatus':data.roomStatus,'date':formattedDate,'province': data.province,'section': data.section,'location': data.location,'address': data.address,'contact': data.contact,'decription': data.decription,'price': data.price,'id': change.doc.id,'imageUrl': data.imageUrl,'ids':data.ids}]);
                    }else if(change.type === "removed"){
                        //arraysOfObject.push([{'province': data.province,'section': data.section,'location': data.location,'address': data.address,'contact': data.contact,'decription': data.decription,'price': data.price,'id': change.doc.id,'imageUrl': data.imageUrl}]);
                    }
  
                }
                
            });
            if(tempArraysOfObject.length !== 0){

                tempArraysOfObject.sort((a, b) => new Date(a[0].date) - new Date(b[0].date));

                let tempArraysOfObjectReverse = tempArraysOfObject.reverse();

                setRefLoading(false);
                setArrayOfArraysOfImageObjects(tempArraysOfObjectReverse);

            }
            setRefLoading(false);
            // if(spinnerState.loading){
            //     setSpinnerState({loading: false,message: ''});
            // }
        });
    },[spinnerState,firestoreRef,username]);

    const [viewFullInfo, setViewFullInfo] = useState(false);
    const [sliderData, setSliderData] = useState([]);
    
    const viewFullInfoMethod = (id) => {
        setViewFullInfo(!viewFullInfo);
        arrayOfArraysOfImageObjects.forEach(arr => {
            if(arr[0].id === id){
                setSliderData(arr);
            }
        });
        
    }

    const confirmDeletion = (ids) => {
        setConfirmDelete({'state':true,'arr':ids});
    }

    const updateRoom = (ids,value) => {
        let theId = ids[0];

        firestoreRef.collection('roomFinder').doc(theId).update({
            roomStatus: value
        }).then(() => {
            setSpinnerState({loading: false,message: ''});
        }).catch((error)=>{
            setSpinnerState({loading: false,message: error.message});
        });
    }
    
    const deleteImageMethod = (deleteImages) => {
        if(deleteImages === 'No'){
            setConfirmDelete({'state':false,'arr':[]});
            return;
        }
        const ids = confirmDelete.arr;

        setConfirmDelete({'state':false,'arr':[]});
        setDeletedImageId(ids[0]);
        setSpinnerState({loading: true,message: ''});
        firestoreRef.collection('roomFinder').doc(ids[0]).delete().then( () =>{
            
            storageRef.child(ids[0]).delete().then(() => {

                storageRef.child(ids[1]).delete().then(() => {

                    // storageRef.child(ids[2]).delete().then(() => {

                    //     storageRef.child(ids[3]).delete().then(() => {
                    //         setSpinnerState({loading: false,message: 'Successfully deleted'});
                    //     }).catch(() => {
                    //       setSpinnerState({loading: false,message: 'Failed to deleted'});
                    //     });
                    // }).catch(() => {
                    //   setSpinnerState({loading: false,message: 'Failed to deleted'});
                    // });
                    setSpinnerState({loading: false,message: 'Successfully deleted'});

                }).catch(() => {
                  setSpinnerState({loading: false,message: 'Failed to deleted'});
                });

            }).catch(() => {
                setSpinnerState({loading: false,message: 'Failed to deleted'});
            });
            
        }).catch(() =>{
            setSpinnerState({loading: false,message: 'Failed to deleted'});
        });

    }
    arrayOfArraysOfImageObjects.forEach((image,index) => {
        const imageId = image[0].id;
        if(deletedImageId === imageId){
            arrayOfArraysOfImageObjects.splice(index,1);
        }

    });
    
    let deleteProperty = null;
    let login = null;
    let imageComp = null;

    if(arrayOfArraysOfImageObjects.length > 0){
        imageComp = <div className={classes.DeletePropertyImageOuterContainer}>
                        <div className={classes.DeletePropertyImageInnerContainer}>
                            <AvailablePropertyImageCard the_images_arry={arrayOfArraysOfImageObjects} deleteProperty="display" clicked={viewFullInfoMethod} clickedDelete={confirmDeletion} updateRoom={updateRoom} type="updateRoom"/>
                        </div>
                    </div>
    
    }else{
        if(refLoading){
            imageComp = <div className={classes.DeletePropertyNoImages}>
                        <div>
                            <h3>Loading...</h3>
                        </div>
                    </div>
        }else{
            imageComp = <div className={classes.DeletePropertyNoImages}>
                            <div>
                                <h3>You did not upload any room</h3>
                            </div>
                        </div>
        }

    }

    if(authenticated){

        deleteProperty =  <div className={classes.DeleteProperty}>

                <div className={classes.DeletePropertyBackgroundContent}>

                        <div className={classes.DeletePropertyHeader}>
                            <div className={classes.ProvinceHeader}>
                                
                            </div>
                            <div className={classes.SectionHeader}>
                               
                            </div>
                        </div>
                    
                </div>
                {imageComp}

           </div>;
        
        }else{
            login = <Redirect to={{
                    pathname: "/login",
                    state: { referrer: "/delete_property" }
                }} />;
        }


        
        let displayAvailableProperty = null;

        let displayViewFullImagesInfo = null;

        if(viewFullInfo){
            displayViewFullImagesInfo = <ImageSlider slides={sliderData} clicked={viewFullInfoMethod}/>;
        }else{
            displayAvailableProperty = <div>
                                            {deleteProperty}
                                            {login}
                                            <DescriptionSection />
                                            <ContactUs />
                                       </div>;
        }
        
        const removePopUp = (response) => {
            if(response === 'OK'){
                setSpinnerState({loading: false,message: ""});
            }
        }
    
        let theSpinner = null;
        let backDrop = null;
        let popup = null;
    
        if(spinnerState.loading){
            theSpinner = <Spinner />
            backDrop = <Backdrop sideDrawerState={spinnerState.loading} />
        }
        if((spinnerState.message).length !== 0){
            popup = <Popup message={spinnerState.message} type="oneButton" clicked={removePopUp}/>
            backDrop = <Backdrop sideDrawerState={true} />
        }else if(confirmDelete.state){
            popup = <Popup message="Are you sure you want to delete" type="twoButton" clicked={deleteImageMethod}/>
            backDrop = <Backdrop sideDrawerState={true} />            
        }

        return <div>
                   
                    <div className={classes.DeleteProperty_Spinner}>
                            {backDrop}
                            {theSpinner}
                    </div>
                    {popup}
                   {displayViewFullImagesInfo}
                   {displayAvailableProperty}
               </div>;
}

export default DeleteProperty;