import classes from './Register.module.css';
import DescriptionSection from '../../components/DescriptionSection/DescriptionSection';
import ContactUs from '../../components/ContactUs/ContactUs';
import Spinner from '../../components/Spinner/Spinner';
import Backdrop from '../../components/Backdrop/Backdrop';
import Popup from '../../components/Popup/Popup';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import * as actions from '../../store/action/index';
import firebase from '../../firebaseStorage/firebase';
import md5 from 'md5';
import saltPas from '../../SaltPas/SaltPas';
const Register = (props) => {

    const dispatch = useDispatch();
    dispatch(actions.propertysearching('','','',false));
    const authenticated = useSelector(state => {
        return state.auth.authenticated;
    });
    const formDataObject = {username: "",password: "",confirmpassword: "",primaryschool: ""};

    const firestoreRef = firebase.firestore();

    const [formData,setFormData] = useState(formDataObject);
    const [spinnerState,setSpinnerState] = useState({loading: false,message: ""});

    function validateEmail(username) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(username).toLowerCase());
    }

    const inputChangedHandler = (event,identifier) => {

        let updateedFormValues = {
                ...formData,
                [identifier]: event.target.value.trim()
        }
        setFormData(updateedFormValues);
    }

    const formHandler =async (event) => {
        event.preventDefault();

        setSpinnerState({loading: true,message: ''});

        if(!validateEmail(formData.username)){
            setSpinnerState({loading: false,message: "Enter correct email"});
            return;
        }else if(formData.password !== formData.confirmpassword){
            setSpinnerState({loading: false,message: "Passwords are not matching"});
            return;
        }else if(formData.password.length < 4 || formData.confirmpassword.length < 4){
            setSpinnerState({loading: false,message: "Enter atleast 4 characters of Password"});
            return;
        }else if(formData.primaryschool.length === 0){
            setSpinnerState({loading: false,message: "Primary school cannot be empty"});
            return;
        }

        const userN = md5(formData.username)+saltPas;
        const userP = md5(formData.password)+saltPas;
        const userPR = md5(formData.primaryschool)+saltPas;

        const encryptData = {
                            username: userN.substring(15, userN.length),
                            password: userP.substring(15, userP.length),
                            confirmpassword: userP.substring(15, userP.length),
                            primaryschool: userPR.substring(15, userPR.length)
                           }

        
        
        let checkState = 0;
        let query = await firestoreRef.collection('roomFinderRegistration');
        query = query.where('username', '==', encryptData.username);
        query.onSnapshot((querySnapshot) => {

            let dataArray = querySnapshot.docs.map(doc =>doc.data());
            if(checkState === 0){

                checkState = 1;
                if(dataArray.length !== 0){
                    setSpinnerState({loading: false,message: formData.username+' already exist'});
                }else{
                    const the_date = new Date();  
                    firestoreRef.collection('roomFinderRegistration').add({
                        ...encryptData,
                        date: the_date.toString()
                    }).then(() => {
                        setSpinnerState({loading: false,message: 'Registered, you can upload room'});
                        setFormData(formDataObject);
                        dispatch(actions.authentication(true, encryptData.username,''));
                        return;
                        
                    }).catch(()=>{
                        setSpinnerState({loading: false,message: 'Failed to submit, please try again'});
                    });
                }

            }
            
            
        });


    }
    
    const removePopUp = (response) => {
        if(response === 'OK'){
            setSpinnerState({loading: false,message: ""});
        }
    }

    let theSpinner = null;
    let backDrop = null;
    let popup = null;

    if(spinnerState.loading){
        theSpinner = <Spinner />
        backDrop = <Backdrop sideDrawerState={spinnerState.loading} />
    }
    if((spinnerState.message).length !== 0){
        popup = <Popup message={spinnerState.message} type="oneButton" clicked={removePopUp}/>
        backDrop = <Backdrop sideDrawerState={true}/>
    }

    let register = null;
    if(!authenticated){
       register =   <div className={classes.Register}>
        
                <div className={classes.RegisterBackgroundContent}>

                    <div className={classes.RegisterHeader}>
                        <h1>LANDLORD TO REGISTER</h1>
                    </div>
                    <div className={classes.RegisterUnderline}></div>
                    
                    <div className={classes.Login_Spinner}>
                            {backDrop}
                            {theSpinner}
                    </div>
                    {popup}
                    <div className={classes.RegisterFormCotainer}>
                            <form onSubmit={formHandler} className={classes.RegisterForm}>
                                    <label htmlFor="username">Username</label>
                                    <input type="text" name="username" placeholder="e.g name@gmail.com" value={formData.name} onChange={event => inputChangedHandler(event,"username")} required minLength="4" maxLength="30"/>
                                    <label htmlFor="password">Password</label>
                                    <input type="password" name="password" placeholder="" value={formData.name} onChange={event => inputChangedHandler(event,"password")} required minLength="4"  maxLength="40"/>
                                    <label htmlFor="confirmpassword">Confirm Password</label>
                                    <input type="password" name="confirmpassword" placeholder="" value={formData.confirm_password} onChange={event => inputChangedHandler(event,"confirmpassword")} required minLength="4" maxLength="40"/>
                                    <label htmlFor="primaryschool">Name of your first school</label>
                                    <input type="text" name="primaryschool" placeholder="" value={formData.name} onChange={event => inputChangedHandler(event,"primaryschool")} required minLength="1" maxLength="30"/>
                                    <button>SUBMIT</button>
                            </form>
                    </div>
                    </div>
            </div>;
    }
    return <div>
            { authenticated ? <Redirect to='/upload_property'/> : register}
            <DescriptionSection />
            <ContactUs />
        </div>;
};
 
 export default Register;