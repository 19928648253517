import classes from './Popup.module.css';
const Popup = (props) => {



   let popup = null;

   if(props.type === 'oneButton'){
        popup = <div className={classes.Popup}>
                        <h2>{props.message}</h2>
                        <button onClick={() => props.clicked('OK')}>OK</button>
                </div>;
   }else if(props.type === 'twoButton'){
        popup = <div className={classes.PopupWithTwoButtons}>
                    <h2>{props.message}</h2>
                    <div className={classes.buttonDiv}>
                        <button className={classes.twoButtonOne} onClick={() => props.clicked('No')}>No</button>
                        <button className={classes.twoButtonTwo} onClick={() => props.clicked('Yes')}>Yes</button>
                    </div>
                </div>;
   }

    return popup;
}

export default Popup;