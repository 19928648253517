import * as actionTypes from './actionTypes';

export const propertysearching = (prov,loc,sec,search) => {
    return {
           type: actionTypes.PROPERTY_SEARCH,
           province: prov,
           location: loc,
           section: sec,
           searching: search
        }
}