import React, { useRef } from "react";
import {useEffect,useState} from 'react';
import classes from './AvailableProperty.module.css';
import AvailablePropertyImageCard from '../../components/AvailablePropertyImageCard/AvailablePropertyImageCard';
import DescriptionSection from '../../components/DescriptionSection/DescriptionSection';
import ContactUs from '../../components/ContactUs/ContactUs';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import firebase from '../../firebaseStorage/firebase';
import { useSelector } from 'react-redux';
const AvailableProperty = () => {

    const redux_province = useSelector(state => {
        return state.search.province;
    });

    const redux_location = useSelector(state => {
        return state.search.location;
    });

    const redux_section = useSelector(state => {
        return state.search.section;
    });

    const redux_searching = useSelector(state => {
        return state.search.searching;
    });

    const [arrayOfArraysOfImageObjects,setArrayOfArraysOfImageObjects] = useState([]);

    const [refLoading,setRefLoading] = useState(true);

    const firestoreRef = firebase.firestore();

    let checkSectionMatch = (sec,exact_sec) => {

        if(sec.toLowerCase().normalize() === exact_sec.toLowerCase().normalize()){
            return true;
        }else{
            return false;
        }
    }
    let checkLocationMatch = (loc,exact_loc) => {
        if(loc.toLowerCase() === exact_loc.toLowerCase()){
            return true;
        }else{
            return false;
        }
    }

    useEffect(() =>{
        let arraysOfObject = [];
        let tempArraysOfObject = [];
        let tempSection = [];
        let tempLocation = [];
        let tempProvince = [];

        if(redux_searching){
           
                setRefLoading(true);
                firestoreRef.collection('roomFinder').where("province","==",redux_province).onSnapshot(snapshort => {

                    let changes = snapshort.docChanges();

                    changes.forEach(change => {
                        const data = change.doc.data();
                        
                        if(change.type === "added"){
                            if(checkSectionMatch(redux_section,data.section)){
                                tempSection.push([{'roomStatus':data.roomStatus,'province': data.province,'section': data.section,'location': data.location,'address': data.address,'contact': data.contact,'decription': data.decription,'price': data.price,'id': change.doc.id,'imageUrl': data.imageUrl}]);
                            }else if(checkLocationMatch(redux_location,data.location)){
                                tempLocation.push([{'roomStatus':data.roomStatus,'province': data.province,'section': data.section,'location': data.location,'address': data.address,'contact': data.contact,'decription': data.decription,'price': data.price,'id': change.doc.id,'imageUrl': data.imageUrl}]);
                            }else{
                                tempProvince.push([{'roomStatus':data.roomStatus,'province': data.province,'section': data.section,'location': data.location,'address': data.address,'contact': data.contact,'decription': data.decription,'price': data.price,'id': change.doc.id,'imageUrl': data.imageUrl}]);
                            }
                        }else if(change.type === "removed"){
                            //arraysOfObject.push([{'province': data.province,'section': data.section,'location': data.location,'address': data.address,'contact': data.contact,'decription': data.decription,'price': data.price,'id': change.doc.id,'imageUrl': data.imageUrl}]);
                        }
                    });
                    
                    const reversedSectionArray = tempSection.reverse();
                    reversedSectionArray.forEach(image => {
                        arraysOfObject.push(image);
                    });
                    
                    const reversedLocationdArray = tempLocation.reverse();
                    reversedLocationdArray.forEach(image => {
                        arraysOfObject.push(image);
                    });

                    const reversedProvinceArray = tempProvince.reverse();
                    reversedProvinceArray.forEach(image => {
                        arraysOfObject.push(image);
                    });

                    if(reversedLocationdArray.length != 0){
                        setArrayOfArraysOfImageObjects(arraysOfObject);
                    }

                    setRefLoading(false);
                    
                });

        }else{
            setRefLoading(true);
            firestoreRef.collection('roomFinder').onSnapshot(snapshort => {
                let changes = snapshort.docChanges();
                
                changes.forEach(change => {
                    const data = change.doc.data();

                    //--------------------
                    let date = new Date(data.date);

                    let year = date.getFullYear();
                    let month = String(date.getMonth() + 1).padStart(2, '0'); 
                    let day = String(date.getDate()).padStart(2, '0');
                    
                    let formattedDate = `${year}-${month}-${day}`;
                    
                    //---------------------
                    
                    if(change.type === "added"){
                        tempArraysOfObject.push([{'roomStatus':data.roomStatus,'date':formattedDate,'province': data.province,'section': data.section,'location': data.location,'address': data.address,'contact': data.contact,'decription': data.decription,'price': data.price,'id': change.doc.id,'imageUrl': data.imageUrl}]);
    
                    }else if(change.type === "removed"){
                        //arraysOfObject.push([{'province': data.province,'section': data.section,'location': data.location,'address': data.address,'contact': data.contact,'decription': data.decription,'price': data.price,'id': change.doc.id,'imageUrl': data.imageUrl}]);
                    }
                });
                tempArraysOfObject.sort((a, b) => new Date(a[0].date) - new Date(b[0].date));

                let tempArraysOfObjectReverse = tempArraysOfObject.reverse();

                setRefLoading(false);
                setArrayOfArraysOfImageObjects(tempArraysOfObjectReverse);
                
            });
        }
        
        
    },[firestoreRef,redux_location,redux_province,redux_searching,redux_section]);

    
    const [viewFullInfo, setViewFullInfo] = useState(false);
    const [sliderData, setSliderData] = useState([]);

    const viewFullInfoMethod = (id) => {
        setViewFullInfo(!viewFullInfo);
        arrayOfArraysOfImageObjects.forEach(arr => {
            if(arr[0].id === id){
                setSliderData(arr);
            }
        });

    }

    const province = redux_province;
    const section =  redux_location;

    let imageComp = null;
    if(arrayOfArraysOfImageObjects.length > 0){
        imageComp = <div className={classes.AvailablePropertyImageOuterContainer}>
                        <div className={classes.AvailablePropertyImageInnerContainer}>
                            <AvailablePropertyImageCard the_images_arry={arrayOfArraysOfImageObjects} clicked={viewFullInfoMethod} type="availableRoom"/>
                        </div>
                    </div>
    }else{
        if(refLoading){
            imageComp = <div className={classes.AvailablePropertyNoImages}>
                        <div>
                             <h3>Loading...</h3>
                        </div>
                    </div>
        }else{
            imageComp = <div className={classes.AvailablePropertyNoImages}>
                        <div>
                             <h3>No room available</h3>
                        </div>
                    </div>

        }
        
    }

    let availableProperty =  null;

    if(redux_searching){

        availableProperty =   <div className={classes.AvailableProperty}>

                                    <div className={classes.AvailablePropertyBackgroundContent}>

                                            <div className={classes.AvailablePropertyHeader}>
                                                <div className={classes.ProvinceHeader}>
                                                    <h2>Province: {province}</h2> 
                                                </div>
                                                <div className={classes.SectionHeader}>
                                                    <h2>Location: {section}</h2> 
                                                </div>
                                            </div>
                                        
                                    </div>
                                    {imageComp}
                

                            </div>;

    }else{
        availableProperty =   <div className={classes.AvailableProperty}>

                                    <div className={classes.AvailablePropertyBackgroundContent}>

                                            <div className={classes.AvailablePropertyHeader}>
                                                <div className={classes.ProvinceHeader1}>
                                                </div>
                                                <div className={classes.SectionHeader1}>
                                                </div>
                                            </div>
                                        
                                    </div>
                                    {imageComp}
                

                            </div>;
    }

        let displayAvailableProperty = null;

        let displayViewFullImagesInfo = null;

        if(viewFullInfo){
            displayViewFullImagesInfo = <ImageSlider slides={sliderData} clicked={viewFullInfoMethod}/>;
        }else{
            displayAvailableProperty = <div>
                                            {availableProperty}
                                            <DescriptionSection />
                                            <ContactUs />
                                       </div>;
        }
        return <div>
                   {displayViewFullImagesInfo}
                   {displayAvailableProperty}
               </div>;
}
export default AvailableProperty;