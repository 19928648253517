import * as actionTypes from '../action/actionTypes';

const initialState = {
    province: '',
    location: '',
    section: '',
    searching: false
};

const propertysearching = (action ) => {
    return { province: action.province,location: action.location,section: action.section,searching: action.searching};
};

const propertysearch = ( state = initialState, action ) => {
    
    switch ( action.type ) {
        case actionTypes.PROPERTY_SEARCH: return propertysearching(action);
        default:
            return state;
    }
};

export default propertysearch;