import classes from './DescriptionSection.module.css';
const DescriptionSection = () => {
    return <div className={classes.DescriptionSection}>
               <div>
                    <h1 className={classes.DescriptionSection__Header}>We Can Make Life Easier</h1>
               </div>
               <div className={classes.DescriptionSection__underline}></div>
               <div className={classes.DescriptionSection__message}><p>We are committed to helping the 
                    community in South Africa find rental rooms and assisting landlords in connecting with tenants.
                     So what are you waiting for? Upload your room for free!</p></div>
            </div>;
}
export default DescriptionSection;