import classes from './AboutUs.module.css';
import AboutUsCard from '../../components/AboutUsCard/AboutUsCard';
import DescriptionSection from '../../components/DescriptionSection/DescriptionSection';
import ContactUs from '../../components/ContactUs/ContactUs';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/action/index';
const AboutUs = () => {

    const dispatch = useDispatch();
    dispatch(actions.propertysearching('','','',false));

    const aboutUsCardInfo = [
                             {"header":"THE EVOLUTION OF OUR ROLE AS COMPILATION TECH",
                             "list": ["We Simplify Life Through Technology"]
                             },
                             {"header":"BENEFITS OF UTILIZING ROOM GET",
                             "list": ["Uploading Your Room Is Free",
                             "It's Free to Search for Rooms",
                             "We Designed It to Be Simple and Easy to Use"]
                             }
                            ];


    const aboutUs = <div>

            <div className={classes.AboutUs}>
                            <div className={classes.AboutUsBackgroundContent}>
                                <AboutUsCard cardInfo={aboutUsCardInfo[0]}/>
                                <AboutUsCard cardInfo={aboutUsCardInfo[1]}/>
                            </div>
                            
                    </div>

             
        </div>;

    return <div>
               {aboutUs}
               <DescriptionSection />
               <ContactUs />
           </div>;
}
export default AboutUs;